import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    staticClass: "fill-height justify-center",
    attrs: {
      "fluid": ""
    }
  }, [_c('ContentArea', {
    staticClass: "d-flex flex-column align-center justify-center"
  }, [_c('Loading', {
    attrs: {
      "value": _vm.processing,
      "modal": ""
    }
  }), _vm._v(" "), _c('ImagesSendwow', {
    attrs: {
      "height": "86"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "content-child d-flex flex-column mt-6"
  }, [_c('Input', {
    attrs: {
      "id": "email",
      "label": _vm.$t('email')
    },
    model: {
      value: _vm.username,
      callback: function callback($$v) {
        _vm.username = $$v;
      },
      expression: "username"
    }
  }), _vm._v(" "), _c('Input', {
    staticClass: "mt-4",
    attrs: {
      "id": "password",
      "label": _vm.$t('password'),
      "type": _vm.visiblePassword ? 'text' : 'password',
      "append-icon": _vm.visiblePassword ? 'mdi-eye' : 'mdi-eye-off'
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        if ($event.ctrlKey || $event.shiftKey || $event.altKey || $event.metaKey) return null;
        return _vm.login.apply(null, arguments);
      },
      "click:append": function clickAppend($event) {
        _vm.visiblePassword = !_vm.visiblePassword;
      }
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  })], 1), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-fill-primary mt-4 tw-w-full tw-max-w-[304px]",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.login
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('login')) + "\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "d-flex my-6 content-child align-center"
  }, [_c(VDivider), _vm._v(" "), _c('div', {
    staticClass: "mx-2 text-body-1"
  }, [_vm._v(_vm._s(_vm.$t('or')))]), _vm._v(" "), _c(VDivider)], 1), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-outline-border tw-w-full tw-max-w-[304px]",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.loginWithGoogle
    }
  }, [_c('ImagesGoogle', {
    staticClass: "mr-2"
  }), _vm._v(" "), _c('span', {
    staticClass: "tw-text-on-surface tw-text-opacity-70"
  }, [_vm._v("\n        " + _vm._s(_vm.$t('loginWithGoogle')) + "\n      ")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };